export const systemConfigKeys = [
    'REACT_APP_ADMIN_APP_CLIENT_ID',
    'REACT_APP_ADMIN_AUTH_CLIENT_ID',
    'REACT_APP_BASE_URL',
    'REACT_APP_THEME',
    'REACT_APP_TITLE',
    'REACT_APP_LANGUAGE',
    'REACT_APP_DEBUG',
    'REACT_APP_FORCE_HTTPS',
];
